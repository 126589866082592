const thankNotes = [
  "للنّجاح أناس يُقدّرون معناه، وللإبداع أُناسٌ يحصدونه، لذا نقدّر جهودك المُضنية، فأنتَ أهلٌ للشّكر والتّقدير ووجب علينا تقديرك، لك منّا كلّ الثّناء والتّقدير.",
  "تتسابق الكلمات وتتزاحم العبارات لتُنظّم عقد الشّكر الذي لا يستحقّه إلّا أنت، إليك يا من كان له قدم السّبق في ركب العلم والتّعليم، إليك يا من بذلت ولم تنتظر العطاء، إليك أهدي عبارات الشّكر والتّقدير.",
  "إن قلتُ شكراً فشكري لن يوفيكم، حقاً سعيتم فكان السّعي مشكوراً، إن جفّ حبري عن التّعبير يكتبكم قلبٌ به صفاء الحبّ تعبيراً.",
  "إلى صاحب التميّز والأفكار النيّرة، أزكى التحيّات وأجملها وأنداها وأطيبها، أرسلها لك بكلّ ودّ وحبّ وإخلاص، تعجز الحروف أن تكتب ما يحمل قلبي من تقدير واحترام، وأن تصف ما اختلج بملء فؤادي من ثناء وإعجاب. فما أجمل أن يكون الإنسان شمعةً تُنير دروب الحائرين.",
  "تلوح في سمائنا دوماً نجوم برّاقة لا يخفت بريقها عنّا لحظةً واحدةً، نترقّب إضاءاتها بقلوب ولهانة، ونسعد بلمعانها في سمائنا كلّ ساعة، فاسّتحقت وبكل فخر أن ترفع اسمها عالياً. كلمة حبّ و تقدير وتحيّة وفاء وإخلاص، تحيّة ملؤها كلّ معاني الأخوّة والصّداقة، تحيّة من القلب إلى القلب، شكراً من كلّ قلبي",
  "عمل المعروف يدوم، والجميل دايم محفوظ، لا تفكر في يوم أنسى أنّك وقفت جنبي على طول.",
  "كلمة شكر وعرفان إلى صاحب القلب الطّيب، إلى صاحب النّفس الأبيّة، إلى صاحب الابتسامه الفريدة، إلى من حارب وساهم الكثير من أجلي.",
  "يا طيور المحبّة زوريه، وعن شكري له خبريه و قوليله عنك ما نستغني لو نلفّ العالم و اللّي فيه.",
  "رسالة أبعثها مليئة بالحبّ والتّقديروالاحترام، ولو أنّني أوتيت كلّ بلاغة وأفنيت بحر النّطق في النّظم والنّثر لما كنت بعد القول إلّا مُقصّراً ومُعترفاً بالعجز عن واجب الشّكر.",
  "أنا عمري ما شكرت إنسان لأنّ المعروف صعب تلاقيه بهالزّمان إلّا في قلب صافي ولهان يحبّ يساعد كلّ إنسان.",
  "كلمة شكراً ما تكفي، والمعنى أكبر ما توفيه، لو بيدي العمر أعطيه أعبّر له عن مدى شكري.",
  "من أيّ أبواب الثّناء سندخل؟ وبأيّ أبيات القصيد نعبّر؟ في كل لمسة من جودكم وأكفِّكم للمُكرّمات أسطّر، كنت كسحابة معطاءة سقت الأرض فاخضرّت.",
  "كنتَ ولا زلتَ كالنّخلة الشّامخة تُعطي بلا حدود، فجزاك عنّا أفضل ما جزى العاملين المخلصين، وبارك الله لك وأسعدك أينما حطّت بك الرِّحال.",
  "لكلّ مبدع إنجاز، ولكلّ شكر قصيدة، ولكلّ مقامٍ مقال، ولكلّ نجاح شكر وتقدير، فجزيل الشّكر نُهديك وربّ العرش يحميك.",
  "عبر نفحات النّسيم وأريج الأزاهير وخيوط الأصيل أرسل لك شكراً من الأعماق.",
];

const randGreet = () => {
  let item = thankNotes[Math.floor(Math.random() * (thankNotes.length - 1))];
  return item;
};

const data = {
  randGreet,
};

export default data;
