import { React } from "react";

import "./About.css";
import { Row, Col, Container, Carousel } from "react-bootstrap";
import logo from "./DAL/logo.png";
import thumbOne from "./DAL/thumbs/1.mp4";
import thumbTwo from "./DAL/thumbs/2.mp4";
import thumbThree from "./DAL/thumbs/3.mp4";
import thumbFour from "./DAL/thumbs/4.mp4";
import thumbFive from "./DAL/thumbs/5.mp4";
import thumbSix from "./DAL/thumbs/6.mp4";

function About() {
  return (
    <Container className="about-container" fluid>
      <Row>
        <Col align="center">
          <img src={logo} className="logo-img" alt="logo" />
        </Col>
      </Row>
      <Row className="about-txt-row">
        <Col>
          <div dir="rtl" className="about-txt">
            <p>
              منصتنا الالكترونية تقدم مجموعة من الهدايا الفريدة لجميع اللحظات
              والمشاعر والمناسبات الرائعة والمهمة التي تمكنك من التعبير لأي شخص
              عن مدى حبك واهتمامك واشتياقك وكل ذلك من خلال هدايا مميزة وعصرية
              المعدة بطلب شخصي. 🎁🎨🧵
            </p>
            <p>
              المتعة متوفرة لدينا بكافة الاحوال، مرسل انت ام مستلم للهدية،
              المتعة سترافقك للابد. 😃😎🤩
            </p>
          </div>
        </Col>
      </Row>
      <Row className="car-row">
        <Col align="center">
          <Carousel indicators={false} className="carousel-comp">
            <Carousel.Item interval={4000}>
              <video
                autoPlay
                loop
                muted
                className="thumb-video"
                style={{ height: 200, width: 200 }}
              >
                <source src={thumbOne} type="video/mp4" />
                <source src={thumbOne} type="video/ogg" />
              </video>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <video
                autoPlay
                loop
                muted
                className="thumb-video"
                style={{ height: 200, width: 200 }}
              >
                <source src={thumbTwo} type="video/mp4" />
                <source src={thumbTwo} type="video/ogg" />
              </video>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <video
                autoPlay
                loop
                muted
                className="thumb-video"
                style={{ height: 200, width: 200 }}
              >
                <source src={thumbThree} type="video/mp4" />
                <source src={thumbThree} type="video/ogg" />
              </video>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <video
                autoPlay
                loop
                muted
                className="thumb-video"
                style={{ height: 200, width: 200 }}
              >
                <source src={thumbFour} type="video/mp4" />
                <source src={thumbFour} type="video/ogg" />
              </video>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <video
                autoPlay
                loop
                muted
                className="thumb-video"
                style={{ height: 200, width: 200 }}
              >
                <source src={thumbFive} type="video/mp4" />
                <source src={thumbFive} type="video/ogg" />
              </video>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <video
                autoPlay
                loop
                muted
                className="thumb-video"
                style={{ height: 200, width: 200 }}
              >
                <source src={thumbSix} type="video/mp4" />
                <source src={thumbSix} type="video/ogg" />
              </video>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
