import { React, useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Spinner, Row, Col, Container, Button } from "react-bootstrap";

import api from "./DAL/api";

import "./Player.css";

function Player({ pending }) {
  let [searchParams, setSearchParams] = useSearchParams();
  let link = searchParams.get("video");
  let url = `https://player.vimeo.com/video/${link}?h=cf15be004e`;
  const thumb = `https://vumbnail.com/${link}_medium.jpg`;

  //add - use effect, check if !video, usehistory to redirtect to about us comp
  let navigate = useNavigate();

  useEffect(() => {
    if (!link) {
      navigate("/about");
    }
  }, [link, navigate]);

  const [greets, setGreets] = useState(api.randGreet());

  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const delay = async (ms) => new Promise((res) => setTimeout(res, ms));
  const submitForm = async () => {
    setSending(true);
    await delay(6000);
    setSent(true);
  };

  const [rate, setRate] = useState([
    <i className="far fa-star empty-star-icon"></i>,
    <i className="far fa-star empty-star-icon"></i>,
    <i className="far fa-star empty-star-icon"></i>,
    <i className="far fa-star empty-star-icon"></i>,
    <i className="far fa-star empty-star-icon"></i>,
  ]);

  const changeRating = (index) => {
    let newRate = rate;
    for (let i = 0; i < newRate.length; i++) {
      if (i <= index) {
        newRate.splice(
          i,
          1,
          <i className="fas fa-star full-star-icon glow"></i>
        );
      } else {
        newRate.splice(i, 1, <i className="far fa-star empty-star-icon"></i>);
      }
    }
    setRate([...newRate]);
  };

  return (
    <Container className="player-container">
      <Row dir="rtl" className="greet-title">
        <Col>
          <span>هديتك الالكترونية </span>
          <span>
            <i className="fas fa-gift"></i>
          </span>
        </Col>
      </Row>
      <div className="embed-container">
        <iframe
          title="vid"
          src={url}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
        <div className="loading-div">
          <span>
            <i className="fas  fa-spinner"></i>
          </span>
        </div>
      </div>
      <Row dir="rtl" className="greet-row">
        <Col align="center">
          <span className="quote-right-icon">
            <i className="fas fa-quote-right"></i>
          </span>
          <span className="greet-span">{greets}</span>
          <span className="quote-left-icon">
            <i className="fas fa-quote-left"></i>
          </span>
        </Col>
      </Row>
      <Row className="star-row">
        <Col>
          {rate.map((item, i) => (
            <span key={i} onClick={() => changeRating(i)}>
              {item}
            </span>
          ))}
        </Col>
      </Row>
      <Row className="thank-row" dir="rtl">
        <Col>
          <Button
            size="lg"
            className={!sent ? "send-button" : "send-button-sent"}
            disabled={pending > 0 ? true : false}
            onClick={submitForm}
          >
            <span>
              {!sending && !sent && "ارسل شكر للمرسل  "}
              {sending && !sent && "يتم الارسال . . ."}
              {sent && "تم ارسال الشكر"}
              {pending > 0 ? `(${pending})` : ""}
            </span>
            <span>
              {!sending && !sent && (
                <i className="fas fa-paper-plane button-icon"></i>
              )}
              {sending && !sent && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="load-spinner"
                  size="sm"
                />
              )}
              {sent && <i className="fas fa-check-double button-icon"></i>}
            </span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Player;
