import { React, useState, useEffect } from "react";
import "./App.css";
import clip from "./DAL/clip.mp4";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Player from "./Player";
import About from "./About";
import Order from "./Order";

function App() {
  const [pending, setPending] = useState(45);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (pending > 0) {
      setTimeout(function () {
        let newPending = pending - 1;
        setPending(newPending);
      }, 1000);
    }
  }, [pending]);

  useEffect(() => {
    if (pending === 0) {
      setChecked(true);
    }
  }, [pending]);

  return (
    <Router>
      <Header loading={pending} checked={checked} />
      <video autoPlay loop muted className="background-video">
        <source src={clip} type="video/mp4" />
        <source src={clip} type="video/ogg" />
      </video>
      <div className="App">
        <Routes>
          <Route exact={true} path="/order" element={<Order />}></Route>
          <Route exact={true} path="/about" element={<About />}></Route>
          <Route
            exact={true}
            path="/"
            element={<Player pending={pending} />}
          ></Route>
          <Route path="/:var" element={<About />}></Route>
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
