import React from "react";
import { Row, Col, Navbar, Container } from "react-bootstrap";
import "./Footer.css";

function Footer() {
  return (
    <Navbar expand="lg" fixed="bottom" className="footer" dir="ltr">
      <Container fluid>
        <Navbar.Brand className="navbar-icons-container">
          <Row>
            <Col align="left">
              <i className="fab fa-vimeo navbar-icons-container"></i>
              <i className="fab fa-tiktok navbar-icons-container"></i>
              <i className="fab fa-linkedin navbar-icons-container"></i>
            </Col>
          </Row>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Footer;
