import { React } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./Order.css";

function Order() {
  return (
    <Container fluid className="contact-container">
      <Row className="contact-row">
        <Col align="center">
          <div className="contact-txt">
            <div className="imessage">
              <p className="from-them" dir="rtl">
                مرحبا! ما هو السعر لمنتجاتكم؟{" "}
              </p>
              <p className="from-me">ياهلا! السعر متفاوت حسب نوع وحجم العمل</p>
              <p className="from-them" dir="rtl">
                اريد ان اطلب منكم رسم - كيف تتم عملية الطلب؟
              </p>
              <p className="from-me">
                ممناز 😎 ندعوك للتواصل معنا عالوتس اب - يمكنك الضغط هنا على
                الرابط
              </p>
              <p className="from-me">
                <i className="far fa-hand-point-right whats-pointer"></i>

                <a href="https://api.whatsapp.com/send?phone=+972543294269">
                  <i className="fab fa-whatsapp whats-link"></i>
                </a>
              </p>
              <p className="from-them emoji">👍</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Order;
